import { useStore } from "@nanostores/react";
import { Fragment, useEffect, useRef } from "react";

import { Advert, updateAdvertory } from "@vgno/adverts";
import { Teaser } from "@vgno/teasers/Teaser";
import SkeletonFeed from "@vgno/teasers/Skeleton";
import VideoList from "@vgno/widgets/VideoList";

import { $teaserStore, fetchTeasers } from "../utils/fetchFront";

import { BuySubscription } from "./BuySubscription";
import { Sidequests } from "./Sidequests/Sidequests";

import type { AdConfig } from "@vgno/astro/@types";
import type { Video } from "@vgno/vg-types";

interface Props {
  advertsInsertionRules?: AdConfig["insertionRules"];
  entertainmentVideos?: Video[];
  isApp?: boolean;
  deviceType: "desktop" | "mobile" | "tablet";
}

export const Feed = ({
  advertsInsertionRules,
  entertainmentVideos,
  isApp,
  deviceType,
}: Props) => {
  const sentinel = useRef<HTMLDivElement | null>(null);
  const { bundles, isFinished, isLoading } = useStore($teaserStore);

  // Intersection Observer for loading more teasers
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries.some((entry) => entry.isIntersecting)) {
          fetchTeasers();
        }
      },
      {
        root: null,
        rootMargin: "100%",
        threshold: 0.1,
      },
    );

    const currentSentinel = sentinel.current;
    if (currentSentinel) {
      observer.observe(currentSentinel);
    }

    return () => {
      if (currentSentinel) {
        observer.unobserve(currentSentinel);
      }
    };
  }, [bundles.length]);

  useEffect(() => {
    const sectionFeed = document.querySelector('section[role="feed"]');

    if (sectionFeed) {
      sectionFeed.setAttribute("aria-busy", isLoading ? "true" : "false");
    }

    if (!isLoading) {
      updateAdvertory({}, { contextSelector: "#feed" });
    }
  }, [isLoading]);

  const widgets = {
    1: !isApp && <BuySubscription deviceType={deviceType} />,
    3: entertainmentVideos && (
      <VideoList
        className="widget"
        data={entertainmentVideos}
        data-track-element-type="Upcoming Broadcasts"
        data-track-id="pluss-entertainment-videos"
        data-track-impression
        data-track-name="Upcoming Broadcasts"
        label="category"
        title="VG+ Underholdning"
        vgtvUrl="https://tv.vg.no/kategori/100324/underholdning"
      />
    ),
    6: <Sidequests className="widget" primaryPosition={6} />,
  };

  return (
    <section
      data-track-curate-context="plussseksjon"
      style={{ display: "contents" }}
    >
      {bundles.map((bundle, bundleIndex) => {
        if (bundle.length === 0) return undefined;

        const advert = advertsInsertionRules?.find(
          (advert) => advert.index === bundleIndex,
        );

        return (
          <Fragment key={bundle[0].id}>
            {advert && (
              <Advert
                placementId={advert.placementId}
                subtype={advert.subtype}
              />
            )}
            {widgets[bundleIndex]}
            {bundle.map((teaser, teaserIndex) => {
              return (
                <Teaser
                  {...teaser}
                  key={teaser.id}
                  tracking={{
                    bundlePosition: bundleIndex + 1,
                    primaryPosition: teaserIndex + 1,
                  }}
                  fetchPriority={bundleIndex === 0 ? "high" : "low"}
                  loading={bundleIndex >= 1 ? "lazy" : "eager"}
                />
              );
            })}
          </Fragment>
        );
      })}
      {!isFinished && (
        <div ref={sentinel}>
          <SkeletonFeed />
        </div>
      )}
    </section>
  );
};
